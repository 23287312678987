<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                招聘英才
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/recruitment/banner-bg.jpg" />

            <div class="mask banner">
                <h3>JOIN US</h3>
                <h4>诚挚邀请英才、汇聚每一份智慧谱写爱创明天</h4>
                <p>
                    We are glad to have like-minded talents participated in itrax to create a wonderful future
                </p>
            </div>
        </div>
        <div class="col-wrap recruitment">
            <img class="recruitment-tit" src="@/assets/mobile/recruitment/tit.png" />
            <p style="font-size:12px; color:#666; margin:0 20px 20px 20px;">
                我们能给的不只是一份高薪酬工作<br />
                更是海阔天空任你翱翔的空间
            </p>
            <ul class="tab">
                <li :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">北京</li>
                <li :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">上海</li>
                <li :class="tabIdx == 3 ? 'active' : ''" @click="tabHandle(3)">广州</li>
                <li :class="tabIdx == 4 ? 'active' : ''" @click="tabHandle(4)">重庆</li>
                <li :class="tabIdx == 5 ? 'active' : ''" @click="tabHandle(5)">杭州</li>
                <li :class="tabIdx == 6 ? 'active' : ''" @click="tabHandle(6)">长春</li>
            </ul>
            <ul class="list">
                <li v-for="item in currentList" :key="item.id" :class="item.active ? 'active' : ''">
                    <a @click="expandItem(item)"
                        ><span>{{ item.title }}</span
                        ><i class="iconfont icon-more"></i
                    ></a>
                    <div class="detail" v-if="item.active" v-html="item.content"></div>
                </li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
export default {
    name: "mobile-recruitment",
    components: { Header, Footer },
    data() {
        return {
            tabIdx: 1,
            list: [],
            currentList: [],
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
            this.filterData();
        },
        expandItem(item) {
            this.currentList.forEach((row) => {
                if (row.id == item.id) row.active = row.active ? false : true;
                else row.active = false;
            });
            this.$forceUpdate();
        },
        filterData() {
            var li = this.list.filter((item) => item.city == this.tabIdx);
            this.currentList = li;
        },
        loadData() {
            this.$showLoading();
            this.$http
                .get(`/api/recruitment/all/detail`)
                .then((res) => {
                    this.list = res.data;
                    this.filterData();
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
@import url("../../../common/css/icon.css");
.list .active a span {
    color: #0048b1;
    font-weight: bold;
}
.list .active a i {
    color: #0048b1;
    transform: rotate(90deg);
}
.list {
    margin: 0px 15px 40px 15px;
    display: flex;
    flex-direction: column;
}
.list li .detail {
    color: #333;
    text-align: left;
    margin: 5px 10px 30px 10px;
    line-height: 22px;
}
.list li {
    border-bottom: 1px #ebebeb solid;
}
.list li a {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 14px;
}
.list li a span {
    flex: 1;
    text-align: left;
    color: #333333;
    margin: 0 10px;
}
.list li a i {
    color: #333333;

    margin: 0 10px;
}
.recruitment .tab {
    margin: 0 15px;
    display: flex;
    border-bottom: 1px #adadad solid;
}
.recruitment .tab li {
    flex: 1;
    padding: 15px 0;
    font-size: 15px;
    color: #666666;
}
.recruitment .tab .active {
    color: #0048b1;
    border-bottom: 3px #0048b1 solid;
}

.recruitment-tit {
    width: 36%;
    margin: 20px 0 0 0;
}
.banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h4 {
    font-size: 15px;
    margin: 20px 0;
}
.banner h3 {
    font-size: 20px;
}
.banner p {
    font-size: 12px;
    margin: 0 15px;
}
</style>
